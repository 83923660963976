<script setup>
import { onMounted, inject, ref } from 'vue'
import { sbapibackoffice } from '@/api'
import { navbarStore } from '@/stores/navbar'
import { spinnerStore } from '@/stores/spinner'

const toast = inject('$toast')
const spinner = spinnerStore()
const navbar = navbarStore()

const visibilities = ref([])
const thisSectionVisible = ref(false)

onMounted(async () => {
  navbar.title = 'Gli ultimi articoli'
  spinner.show()

  try {
    let results = await sbapibackoffice.get('home/sections-visibility')
    if (results?.data) {
      visibilities.value = results.data
      thisSectionVisible.value = results.data.find(v => v.sectionName == 'OUR_SOCIALS').visible
    }
  } catch (error) {
    toast.error('Errore: ' + error.message)
  }

  spinner.hide()
})

const toggleVisibility = async (event) => {
  try {
    const sectionId = visibilities.value.find(v => v.sectionName == 'OUR_SOCIALS').id
    await sbapibackoffice.put(`home/sections-visibility/${sectionId}`, { visible: event.target.checked })

    if (event.target.checked) {
      toast.success('Sezione visibile su cving.com')
    } else {
      toast.success('Sezione nascosta su cving.com')
    }
  } catch (error) {
    toast.error('Errore: ' + error.message)
  }
}
</script>

<template>
  <div class="container">
    <div class="cms_description">
      <p>Sezione automatizzata. Si prevede solo la spunta di visibilità della sezione.</p>
      <!-- switch toggle to hide show the entire cms section -->

      <div class="actions">
        <div class="switch-toggle">
          <input id="switch" v-model="thisSectionVisible" type="checkbox" @change="toggleVisibility">
          <label for="switch">Visibile su cving.com</label>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped lang="scss">
.container { padding: 20px; }

.cms_description {
  color: white;
  font-size: 1rem;
  
  p {
    margin-bottom: 10px;

    font-style: italic;
  }
  
  .actions {
    display: flex;
    justify-content: space-between;
    
    .switch-toggle {
      margin-top: 15px;
      margin-bottom: 30px;
      
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

// .required_symbol {
//   color: red;
//   font-size: 1.5rem;
// }

.table {
  color: white;
  height: calc(100vh - 225px);
  overflow-y: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      background-color: #1a74a3;
      position: sticky;
      top: 0;
      z-index: 100;

      th {
        padding: 10px;
        text-align: left;

        &.number_column {
          width: auto;
          max-width: 82px;
        }

        &.date_column {
          width: 260px;
        }
      }
    }
    tbody {
      user-select: none;
      border-radius: 14px;
      tr {
        border-right: 1px solid #f4f4f438;
        border-left: 1px solid #f4f4f438;
        
        td {
          padding: 20px 10px;
          border-bottom: 1px solid #f4f4f438;
          border-radius: 14px;
          
          input {
            color: white;
          }

          .deleteItem {
            cursor: pointer;
            width: 16px;
            height: auto;
            color: rgb(253, 102, 102);
          }

          .saveRow {
            cursor: pointer;
            width: 16px;
            height: auto;
            color: rgb(102, 253, 102);
          }

          .dragItem {
            cursor: grab;
            height: auto;
            min-width: 12px;
          }

          .company_column {
            min-width: 200px;
          }

          .text_column {
            width: auto;

            input {
              width: 100%;
              background: transparent;
              border-radius: 4px;
              padding: 12px;
              outline: none;
              font-size: 14px;
              border: 1px solid #d1d5db;
            }
          }

          &.file_column {
            width: auto;
            padding: 0 10px;

            .image-wrapper {
              position:relative;
              width: fit-content;

              img {
                max-width: 300px;
              }
            }

            .add-file {
              max-width: 130px;
              min-width: unset !important;
              overflow: visible !important;
              white-space: normal;
              line-height: unset !important;
              font-size: 10.5px;
              background: var(--info);
              color: white;
              text-transform: uppercase;
              outline: 0;
              box-shadow: none;
              border-radius: 4px;
              border: 2px solid var(--info);
              height: 41px;
              padding: 0 10px;
            }

            .delete-item-image {
              position: absolute;
              top: 5px;
              right: 5px;
              cursor: pointer;
              width: 16px;
              height: auto;
              color: rgb(253, 102, 102);
            }
          }

          &.number_column {
            width: auto;
            max-width: 82px;

            input {
              width: 100%;
              background: transparent;
              border-radius: 4px;
              padding: 12px;
              outline: none;
              font-size: 14px;
              border: 1px solid #d1d5db;
            }
          }

          &.date_column {
            width: 260px;
          }
        }
      }
    }
  }
}

// hide number input arrows
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style>
.date_column input {
  background-color: transparent;
  color: white;
  height: 41px;
}
</style>